import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../../components/layout"
import SEO from "/src/components/seo"
import BannerFullScreen from "../../components/BannerFullScreen/BannerFullScreen"
import ButtonContact from "../../components/BannerFullScreen/ButtonContact"
import WhiteBoxBordered from "../../components/ColoredBoxes/WhiteBoxBordered"
import "./Services.scss"
import { Link } from "gatsby"
import CustomAccordion from "../../components/Accordion"
import StatisticNumbers from "../../components/StatisticNumbers/StatisticNumbers"
import BlueBox from "../../components/ColoredBoxes/BlueBox"
import WhiteBox from "../../components/ColoredBoxes/WhiteBox"
import QuoteBlocks from "../../components/QuoteBlock/QuoteBlock"
import ContactForm from "../../components/Contact/contactForm/ContactForm"

const bannerContent = {
    title: "Generative AI Consulting",
    content: "Empower businesses of all sizes to harness the transformative power of GenAI to achieve groundbreaking results. ",
    backgroundImage: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/genai-consulting-banner.jpg`,
    layout: "2-columns",
    image: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/gen-ai-banner.png`,
    textColor: "light-yellow",
    showArrow: true
}

const clientList = [
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-incloud.png`,
        linkTo: "/company/case-studies/incloud"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-conexus.png`,
        linkTo: "/company/case-studies/conexus"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-orient.png`,
        linkTo: "/company/case-studies/orient"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-plenti.png`,
        linkTo: "/services/generative-ai-consulting/#"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-bravo.png`,
        linkTo: "/company/case-studies/bravo"
    },
    {
        url: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-fishwell.png`,
        linkTo: "/company/case-studies/fishwell"
    },
]

const genAIBenefits = [
    {
        title: "Revolutionize Workflows",
        content: "Automate repetitive tasks, streamline processes, and free your team to focus on more strategic initiatives."
    },
    {
        title: "Enhance Product Customization",
        content: "Create customized products tailored to specific user requirements at scale."
    },
    {
        title: "Drive Innovation at Warp Speed",
        content: "Accelerate R&D cycles, generate novel ideas, and bring products to market faster."
    },
    {
        title: "Unlock the Power of Data",
        content: "Extract valuable insights, predict trends, and make data-driven decisions for a competitive edge."
    }
]

const statistic = [
    {
        number: "75<span class='h3-text primary-blue'>%</span>",
        text: "increase in productivity"
    },

    {
        number: "30<span class='h3-text primary-blue'>%</span>",
        text: "boost in customer satisfaction"
    },

    {
        number: "<span class='h3-text primary-blue'>$</span>6.1-7.9",
        text: "trillion annual economic impact from generative AI"
    },

    {
        number: "86<span class='h3-text primary-blue'>%</span>",
        text: "of IT leaders foresee GenAI playing a key role soon"
    },
]

const genAIServices = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-path.png`,
        title: "Strategy & Consulting",
        content: "Our Generative AI consulting experts craft a tailored GenAI roadmap aligned with your unique business needs."
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-chart.png`,
        title: "Use Case & Feasibility Analysis",
        content: "We assess the most impactful applications of GenAI for your business goals."
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-shield.png`,
        title: "Compliance and Security Consultation",
        content: "We prioritize data privacy and security throughout the entire process."
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-chart-line.png`,
        title: "Data Engineering",
        content: "We clean, prepare, and structure your data for effective AI training and deployment."
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-code.png`,
        title: "Technology Selection",
        content: "Our team identifies the optimal GenAI models for your unique requirements."
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-image.png`,
        title: "Generative AI Integration",
        content: "We connect GenAI with your existing systems and infrastructure."
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-brain.png`,
        title: "AI Deployment",
        content: "Our AI engineers seamlessly integrate GenAI solutions into your existing systems to ensure a smooth transition."
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-tool.png`,
        title: "Maintenance & Support",
        content: "We offer ongoing support to ensure optimal performance and ROI."
    }
]

const aiModels = [
    {
        title: "MULTIMODAL",
        services: ["GPT-4 and GPT 4o", "Claude 3 and Claude 3.5", "Gemini Pro"]
    },
    {
        title: "TEXT GENERATION",
        services: ["Mistral", "Mixtral", "Llama"]
    },
    {
        title: "IMAGE GENERATION",
        services: ["Diffusion", "DCGAN", "CLIP"]
    },
    {
        title: "CODE GENERATION",
        services: ["GitHub Copi", "AlphaCode", "CodeT5"]
    }
]

const genAIApplications = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-1.png`,
        title: "Software Development",
        hover: ["Code Generation","Automated Testing","Natural Language Processing"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-2.png`,
        title: "Healthcare",
        hover: ["Drug Discovery","Medical Imaging","Personalized Medicine"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-3.png`,
        title: "Advertising & Marketing",
        hover: ["Content Creation","Customer Insights","Chatbots"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-4.png`,
        title: "Manufacturing",
        hover: ["Generative Design","Predictive Maintenance","Quality Control"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-5.png`,
        title: "Banking & Finance",
        hover: ["Fraud Detection","Algorithmic Trading","Customer Service"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-6.png`,
        title: "Entertainment",
        hover: ["Content Generation","Personalized Recommendations","Virtual Reality (VR) and Augmented Reality (AR)"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-7.png`,
        title: "Retail",
        hover: ["Inventory Management","Personalized Shopping Experiences","Visual Search"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-8.png`,
        title: "Energy",
        hover: ["Smart Grids","Predictive Maintenance","Renewable Energy Optimization"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-9.png`,
        title: "Automotive",
        hover: ["Autonomous Vehicles","Predictive Maintenance","In-Vehicle Assistants"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-10.png`,
        title: "Real Estate",
        hover: ["Property Valuation","Virtual Tours","Predictive Analytics"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-11.png`,
        title: "Education",
        hover: ["Personalized Learning","Automated Grading","Virtual Tutors"]
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/icon-12.png`,
        title: "Human Resources",
        hover: ["Talent Acquisition","Employee Engagement","Performance Management"]
    }
]

const deepDive = [
    {
        title: "Deep Learning and NLP",
        content: "Expertly train AI models to understand and generate human-like language."
    },
    {
        title: "Computer Vision and Image Synthesis",
        content: "Develop AI applications for image recognition, object detection, and realistic image generation."
    },
    {
        title: "Reinforcement Learning and Simulation",
        content: "Build AI models that learn through trial and error for optimal decision-making."
    }
]

const partnerWithAIConsultant = [
    {
        title: "Experienced Guidance",
        content: "Our team of AI experts possesses a deep understanding of GenAI technology and its real-world applications."
    },
    {
        title: "Proven Track Record",
        content: "We own a proven track record of successful GenAI implementations across diverse industries."
    },
    {
        title: "Data-Driven Approach",
        content: "Our decisions are guided by data analysis, ensuring your AI strategy aligns with your business goals."
    },
    {
        title: "Future-Proof Solutions",
        content: "Our consultants stay at the forefront of GenAI advancements, ensuring your solutions are cutting-edge."
    },
    {
        title: "End-to-End Support",
        content: "From ideation to implementation, Neurond provides comprehensive support throughout your GenAI journey."
    }
]

const quoteBlocks = [
    {
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-bravo.png`,
        content: "Thanks to Neurond’s Dr.Parser, we’re able to create a compelling talent management system that every HR loves.",
        quoter: "John P.",
        quoterTitle: "Product Development Manager"
    },
    {
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-fishwell.png`,
        content: "Neurond’s AI team has constantly impressed us. Our team at Neurond, within a few months, became a vital and integrated part of Fishwell, sharing the same vision and drive forward, and representing a key force of Fishwell.",
        quoter: "Thomas H.",
        quoterTitle: "CTO"
    },
    {
        logo: `${process.env.GATSBY_CDN_CONFIG}/v2/client-logos/logo-incloud.png`,
        content: "Neurond’s most impressive characteristic is their passion for helping us solve our problems. They truly believe in what we’re trying to achieve. Their members see the value of our work and understand our vision.",
        quoter: "Christopher B.",
        quoterTitle: "Managing Director"
    }
]

const faqs =
[
    {
        title: "What is Generative AI?",
        description: "Generative AI refers to a category of artificial intelligence systems designed to generate new content, such as text, images, music, or even code, similar to the data that it was trained on. These systems use machine learning (ML) models, particularly deep learning techniques, to understand patterns and structures in the training data and then create new, original outputs based on that understanding."
    },
    {
        title: "What can Generative AI do?",
        description: "Generative AI has a wide range of capabilities and applications across various domains. It can generate creative text formats, including poems, scripts, musical pieces, or even emails, with just a few guiding prompts. GenAI applications also produce images based on your descriptions, generate realistic videos, or modify existing ones. Regarding material science or engineering, generative AI can be employed to design new materials with specific properties or create novel product designs."
    },
    {
        title: "What Are the Types of Generative AI Models?",
        description: "There are several types of generative AI models, each with its own strengths and suited to different kinds of tasks. They are Generative Adversarial Networks (GANs), Variational Autoencoders (VAEs), Transformer models, Recurrent Neural Networks (RNNs), long Short-Term Memory Networks (LSTMs), Autoregressive models, Flow-based models, Diffusion models, and Energy-Based models."
    },
    {
        title: "How Is Generative AI Beneficial for Businesses?",
        description: "Generative AI is a powerful tool that can help businesses significantly enhance operations by automating routine tasks, providing personalized experiences, and generating valuable insights. With the help of a generative AI consulting provider and their deep expertise and experience, businesses can maximize the potential of generative AI technologies, ensuring that the AI models are tailored to meet specific business needs and objectives.<br>However, it's essential for businesses to implement these technologies responsibly, considering ethical implications and potential biases."
    }
]

const otherServices = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-chip.png`,
        title: "AI Development",
        linkTo: "/services/artificial-intelligence"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-health.png`,
        title: "Business Intelligence",
        linkTo: "/services/business-intelligence"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-robot-arm.png`,
        title: "Data Engineering",
        linkTo: "/services/data-engineering"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-ioi.png`,
        title: "Data Science <span class=\"d-inline-block\">And Analytics</span> Solutions",
        linkTo: "/services/data-science-and-analytics"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-ai-consulting.png`,
        title: "AI Consutling",
        linkTo: "/services/artificial-intelligence-consulting"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/ai-consulting/icon-mlops.png`,
        title: "MLOps Consulting",
        linkTo: "/services/mlops-consulting"
    },
]

const GenerativeAIConsultingPage = () => {
    const { t } = useTranslation()
    return (
      <Layout>
        <SEO title={t("Generative AI Consulting Services – Neurond AI")} description={"Empower businesses of all sizes to harness the transformative power of GenAI to achieve groundbreaking results."}/>
        <BannerFullScreen item={bannerContent} />

        <section className="wrapper pt-5">
            <h2 className="h2-text gradient-text --uppercase mb-5">Trusted By Our Partners</h2>
            <div className="row m-minus-15 justify-content-center align-items-center text-center pb-2">
                {
                    clientList.map((item, index) => {
                        return (
                            <div className="col-md-3 col-sm-6 col-12 mb-5">
                                <Link to={item.linkTo}><img className="img-fluid" alt={"Logo" + index} src={item.url}/></Link>
                            </div>
                        )
                    })
                }
            </div>
        </section>

        <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-benefits.jpg)`}}>
            <div className="wrapper">
                <h2 className="h2-text gradient-text --uppercase">Benefits of Generative AI</h2>
                <p className="mb-5">GenAI potentially sparks creativity, efficiency, and personalization, drives innovation, and improves user experiences in various industries.</p>
                <div className="row m-minus-15 align-items-center">
                    <div className="offset-lg-1 col-lg-6 col-md-8 col-12">
                        <div className="row m-minus-15">
                            {
                                genAIBenefits.map((item, index) => {
                                    return (
                                        <div className="col-sm-6 col-12 mb-4" key={index}>
                                            <WhiteBoxBordered item={item}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="offset-lg-1 col-md-4 col-12">
                            {
                                statistic.map((item, index) => {
                                    return (
                                        <div className="mb-5" key={index}>
                                            <StatisticNumbers item={item}/>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
            </div>
        </section>

        <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-genai.jpg)`}}>
            <div className="wrapper">
                <div className="row m-minus-15">
                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                        <p className="sub-text-h2">APPROACH</p>
                        <h2 className="h2-text light-yellow --uppercase mb-5">
                            Generative AI Consulting Services
                        </h2>
                        <ButtonContact item={{customClass:"text-white --white-bordered --transparent-bg --no-arrow", customText:"Learn More", linkTo: "#contact-form"}}/>
                    </div>
                    {
                        genAIServices.map((item, index) => {
                            return(
                                <div className="col-lg-4 col-md-6 col-12 mb-5" key={index}>
                                    <BlueBox item={item}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>

        <section className="padding-y-100">
            <div className="wrapper">
                <h2 className="h2-text gradient-text --uppercase text-center mb-4">Our Expertise in Generative AI Models</h2>
                <p className="text-center">Neurond excels in a variety of popular generative AI models, utilizing advanced deep learning techniques, such as neural networks, to perform tasks that involve understanding and generating human-like text, images, audio, and other forms of data. </p>

                <div className="row m-minus-15 mt-5">
                    <div className="col-lg-6 col-12 pl-lg-0 mb-4">
                        <div className="row">
                        {
                            aiModels.map((item, index) => {
                                return (
                                    <div className="col-sm-6 col-12 mb-4" key={index}>
                                        <WhiteBoxBordered item={item}/>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                    <div className="offset-lg-1 col-lg-5 col-12 px-lg-0 mb-4 text-center">
                        <img className="img-fluid" alt="Generative AI model" src={`${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/generative-model.png`}/>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-background padding-y-100 genai-applications" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-genai-applications.jpg)`}}>
            <div className="wrapper">
                <h2 className="h2-text gradient-text --uppercase text-center mb-5">Generative AI Applications <span className="d-inline-block">Across Industries</span></h2>

                <div className="row m-minus-15">
                    {
                        genAIApplications.map((item, index) => {
                            return (
                                <div className="col-lg-3 col-md-4 col-6 mb-4" key={index}>
                                    <WhiteBox item={item}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>

        <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-deep-dive.jpg)`}} >
            <div className="wrapper">
                <div className="row m-minus-15 align-items-center">
                    <div className="col-md-6 col-12 secondary-blue">
                        <h2 className="h2-text gradient-text --uppercase mb-5">Deep Dives: Our Areas of <span className="d-inline-block">Generative AI Expertise</span></h2>
                        <p className="mb-5">Neurond’s consultants are industry leaders in various generative AI disciplines:</p>
                        <ButtonContact item={{customClass:"--bordered --no-arrow", customText:"Learn More", linkTo: "#contact-form"}}/>
                    </div>
                    <div className="offset-lg-1 col-lg-5 col-md-6 col-12">
                        {
                            deepDive.map((item, index) => {
                                if(deepDive.length === index + 1) {
                                    return (
                                        <div key={index}>
                                            <p className="h4-text primary-blue mb-2">{item.title}</p>
                                            <p className="sub-content secondary-blue">{item.content}</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index}>
                                            <p className="h4-text primary-blue mb-2">{item.title}</p>
                                            <p className="sub-content secondary-blue">{item.content}</p>
                                            <hr className="my-4"/>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </section>

        <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-genai.jpg)`}}>
            <div className="wrapper">
                <div className="row m-minus-15">
                    <div className="col-lg-4 col-md-6 col-12 mb-5">
                        <h2 className="h2-text light-yellow --uppercase mb-5">
                            Partner with The Best Generative AI Consultants
                        </h2>
                        <p className="text-white">At Neurond, we are passionate about helping businesses unlock the transformative potential of Generative AI.  What sets us apart? </p>
                    </div>
                    {
                        partnerWithAIConsultant.map((item, index) => {
                            return(
                                <div className="col-lg-4 col-md-6 col-12 mb-5" key={index}>
                                    <BlueBox item={item}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>

        <section className="wrapper padding-y-100 pb-5">
            <div className="row m-minus-15">
                {
                    quoteBlocks.map((item, index) => {
                        return (
                            <div className="col-lg-4 col-md-6 col-12 mb-5" key={index}>
                                <QuoteBlocks item={item} />
                            </div>
                        )
                    })
                }
            </div>
        </section>

        <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-deep-dive.jpg)`}} >
            <div className="wrapper">
                <div className="row m-minus-15 py-4">
                    <div className="col-md-4 col-12">
                        <p className="sub-text-h2">STILL WONDERING ABOUT AI CONSULTING?</p>
                        <h2 className="h2-text gradient-text --uppercase mb-5">
                            We've Got Answers!
                        </h2>
                    </div>
                    <div className="offset-md-1 col-md-7 ai-consulting-faq">
                        <CustomAccordion data={faqs}/>
                    </div>
                </div>
            </div>
        </section>

        <section id="contact-form" className="genai-contact content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-form-1.jpg)`}}>
            <div className="wrapper content-background padding-y-50" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-form-2.jpg)`, borderRadius: "9px"}}>
                <div className="row m-minus-15 py-4">
                    <div className="order-lg-2 offset-lg-1 col-lg-4 col-12 mb-5 mb-lg-0">
                        <h2 className="h2-text --uppercase mb-5">
                            <span className="d-block text-white">Ready for AI Transformation?</span>
                            <span className="d-block light-yellow ">Let's Connect</span>
                        </h2>
                        <p className="text-white">Embrace the future, boost your business performance, and join the AI revolution with Neurond AI Consulting Service today.</p>
                    </div>
                    <div className="order-lg-1 offset-lg-1 col-lg-5 col-12">
                        <div className="genai-contact__form">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-background padding-y-100" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/genai-consulting/background-other-services.jpg)`}}>
            <div className="wrapper">
                <div className="row m-minus-15">
                    <div className="col-12 mb-5">
                        <p className="sub-text-h2">SERVICES</p>
                        <h2 className="h2-text light-yellow --uppercase">
                            Other Services
                        </h2>
                    </div>
                    <div className="col-12">
                        <div className="grid-3by2">
                        {
                            otherServices.map((item, index) => {
                                return(
                                    <WhiteBox item={item}/>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </Layout>
    )
}

export default GenerativeAIConsultingPage
