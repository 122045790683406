import React from "react"
import "./StatisticNumbers.scss"

const StatisticNumbers = prop => {
    return (
        <>
            <p className="h1-text primary-blue --font-weight-exbold" dangerouslySetInnerHTML={{__html: prop.item.number}}></p>
            <p>{prop.item.text}</p>
        </>
    )
}

export default StatisticNumbers
