import React from "react"
import "./WhiteBox.scss"
import { Link } from "gatsby"

const BlueBox = prop => {
    return (
        <div className="blue-box">
            {
                prop.item.icon && (
                    <p className="clearfix">
                        <img className="img-fluid mb-4" alt={prop.item.title + "icon"} src={prop.item.icon}/>
                    </p>
                )
            }
            <h3 className="h3-text --uppercase text-white mb-3">
                {
                    prop.item.linkTo && prop.item.linkTo ? (<Link className="text-white" to={prop.item.linkTo} dangerouslySetInnerHTML={{ __html: prop.item.title }}></Link>) : (<span dangerouslySetInnerHTML={{ __html: prop.item.title }}></span>)
                }
            </h3>
            {
                prop.item.content && (
                    prop.item.services ? (<p className="text-white mb-4">{prop.item.content}</p>) : (<p className="text-white">{prop.item.content}</p>)
                )
            }

            {
                prop.item.services && prop.item.services.map((text, i) => {
                    return (
                        <div className="white-box__list text-white">{text}</div>
                    )
                })
            }
        </div>
    )
}

export default BlueBox
