import React from "react"
import "./WhiteBox.scss"

const WhiteBoxBordered = prop => {
    return (
        <div className="white-box --gradient-border">
            <div className="--gradient-border-module">
                <h3 className="h3-text primary-blue text-center mb-3">{prop.item.title}</h3>

                {
                    prop.item.content && (
                        <p className="text-center">{prop.item.content}</p>
                    )
                }

                {
                    prop.item.services && prop.item.services.map((text, i) => {
                        return (
                            <div className="white-box__list text-center">{text}</div>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default WhiteBoxBordered
